/*
* @Author: Jeff Shields
* @Date:   2019-03-26 15:34:11
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-03-26 17:28:47
*/

.loading-rn{
    display: none;
}
.google-map {
    height: 25rem;
    position: relative;
}

.google-map iframe {
    height: 100%;
    width: 100%;
    // min-height: 300px;
}

#embeded iframe,
.embeded iframe  {
    width: 100%;
}

.overlay {
    background-color: #000;
    opacity: 1;
    // pointer-events: none;
}
// .overlay {
//     display: block !important;
//     opacity: 0;
// }

.overlay:hover {
    background-color: #efefef;
    opacity: .5;
    transition: opacity .3s;
}
